document.addEventListener("turbo:load", () => {
  const body = document.querySelector("#root");
  const button = document.querySelector("#menu-button");
  const backgroundMenu = document.querySelector("#background-menu");

  button?.addEventListener("click", () => {
    if (backgroundMenu.classList.contains("h-screen")) {
      document.getElementById("anim2").beginElement();
    } else {
      document.getElementById("anim1").beginElement();
    }
    backgroundMenu.classList.toggle("h-0");
    backgroundMenu.classList.toggle("h-screen");
    body.classList.toggle("overflow-hidden");
  });
});
