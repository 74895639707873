import React from "react";

export function ProgressBar({ total, remaining }) {
  const progressPercent = ((total - remaining) / total) * 100;

  return (
    <div>
      <p className="text-center text-primary text-base">
        {remaining > 1
          ? `${remaining} perguntas restantes`
          : `${remaining} pergunta restante`}
      </p>
      <div className="max-w-[280px] w-full mx-auto bg-dove-gray h-2 rounded-xl overflow-hidden mt-3">
        <div
          className="h-2 bg-primary  rounded-xl"
          style={{ width: `${progressPercent}%` }}
        />
      </div>
    </div>
  );
}
