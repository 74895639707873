import React, { useRef } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuestionCard from "./QuestionCard";
import Buttons from "./Buttons";
import { ProgressBar } from "./ProgressBar";

const SliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  draggable: false,
  swipe: false,
  arrows: false,
};

export default function QuestionsSlider({
  questions,
  currentQuestionIndex,
  setCurrentQuestion,
  answers,
  updateAnswer,
  loading,
  reStart,
  handleSubmit,
}) {
  const slider = useRef(null);

  const goToNext = () => slider.current?.slickNext();
  const goToPrevious = () => slider.current?.slickPrev();

  const handleBackButton = () => {
    currentQuestionIndex == 0 ? reStart() : goToPrevious();
  };

  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswers = answers[currentQuestion.id] || [];
  const canGoToNext =
    !loading && (!currentQuestion.is_required || !!currentAnswers.length);

  return (
    <div
      id="quote-form-questions"
      className="mx-4 py-6 justify-center bg-custom-dark rounded-lg max-w-2xl px-4 lg:px-24 lg:mx-auto"
    >
      <ProgressBar
        total={questions?.length}
        remaining={questions?.length - currentQuestionIndex}
      />
      {/* <div data-aos="slide-left" className="flex flex-col" data-aos-delay="200"> */}
      <Slider
        ref={slider}
        {...SliderSettings}
        beforeChange={(oldI, newI) => setCurrentQuestion(newI)}
      >
        {questions.map((q) => (
          <QuestionCard
            key={q.id}
            question={q}
            answers={answers[q.id] || []}
            updateAnswer={updateAnswer}
            disabled={loading}
          />
        ))}
      </Slider>
      <Buttons
        goToPrevious={handleBackButton}
        goToNext={goToNext}
        canGoToNext={canGoToNext}
        isFinished={currentQuestionIndex == questions.length - 1}
        onSubmit={handleSubmit}
      />
      {/* </div> */}
    </div>
  );
}
