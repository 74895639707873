import React from "react";

import { X } from "react-feather";
import Logo from "./logo.png";
export default function QuoteFormPageHeader({ onClose }) {
  return (
    <nav className="bg-custom-dark/80 py-4 px-md-4 px-4">
        <div className="flex items-center justify-between">
             <span className="hidden md:block lg:block">
              <img src={Logo} alt="" className="img-fluid" style={{width: 150}}/>
            </span>

            {!!onClose && (
                <div
                    className="text-right hidden md:block lg:block"
                    style={{width: 150}}
                    data-aos="fade"
                    data-aos-delay="1000"
                >
                    <a
                        className="flex flex-col items-end text-white p-2 cursor-pointer"
                        onClick={onClose}
                    >
                        <X color="white" size={25} style={{marginTop: -1}}/>
                    </a>
                </div>
            )}
        </div>
    </nav>
  );
}
