import React, { forwardRef } from "react";

function flatAnswers(answers) {
  return Object.entries(answers)
    .map(([quoteFormQuestionId, answer]) => {
      return Array.isArray(answer)
        ? answer.map((a) => ({ quoteFormQuestionId, quoteFormOptionId: a }))
        : { quoteFormQuestionId, text: answer };
    })
    .flat(1);
}

function SaveValuesForm({ form, answers }, ref) {
  const eachAnswer = flatAnswers(answers);

  return (
    <form ref={ref} action="/calculadora" method="POST">
      <input type="hidden" name="authenticity_token" value={form.authenticity_token} />

      <input type="hidden" name="quote[quote_form_id]" value={form.id} />

      {eachAnswer.map((answer, i) => (
        <div key={i}>
          <input
            type="hidden"
            name={`quote[answers_attributes][${i}][quote_form_question_id]`}
            value={answer.quoteFormQuestionId}
          />

          {answer.quoteFormOptionId && (
            <input
              type="hidden"
              name={`quote[answers_attributes][${i}][quote_form_option_id]`}
              value={answer.quoteFormOptionId}
            />
          )}

          {answer.text && <input type="hidden" name={`quote[answers_attributes][${i}][text]`} value={answer.text} />}
        </div>
      ))}
    </form>
  );
}

export default forwardRef(SaveValuesForm);
