import React, { useRef, useState, useCallback } from "react";

import "./styles.css";

import Header from "./Header";
import QuestionsSlider from "./QuestionsSlider";
import SaveValuesForm from "./SaveValuesForm";
import Background from "./background.png";
import Logo from "./logo.png";

export default function QuoteForm({ form }) {
  const questions = form.questions;
  const submitForm = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});

  const goBack = useCallback(() => {
    window.location.href = "/";
  });

  const handleClose = useCallback(() => {
    if (
      confirm("Seu orçamento ainda não foi salvo. Tem certeza que deseja sair?")
    ) {
      goBack();
    }
  });

  const updateAnswer = (question, answer) => {
    let thisQuestionAnswers = answers[question.id] || [];

    if (question.kind === "choose") {
      const selected = thisQuestionAnswers.includes(answer.id);

      if (!question.is_multiple) thisQuestionAnswers = [];

      if (selected) {
        thisQuestionAnswers = thisQuestionAnswers.filter((i) => i != answer.id);
      } else {
        thisQuestionAnswers = [...thisQuestionAnswers, answer.id];
      }
    } else {
      thisQuestionAnswers = answer;
    }

    setAnswers({ ...answers, [question.id]: thisQuestionAnswers });
  };

  const reStart = () => {};

  const handleSubmit = () => {
    setLoading(true);
    submitForm.current?.submit();
  };

  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswers = answers[currentQuestion.id] || [];
  const canGoToNext =
    !loading && (!currentQuestion.is_required || !!currentAnswers.length);
  const canGoToPrevious = !loading && !!currentQuestionIndex;

  return (
    <main
      id="quote-form-page"
      style={{
        backgroundImage: `url(${Background})`,
      }}
      className="flex flex-col min-h-screen bg-light bg-center bg-no-repeat bg-cover"
    >
      <Header onClose={handleClose} />
      <div className="flex flex-col items-center px-12">
        {/*<img src={Logo} className="w-52" />*/}
        <h1 className="text-white font-semibold text-2xl mt-10 text-center">
          Quanto vou investir para criar um aplicativo?
        </h1>
      </div>
      <div className="mt-12 mb-20">
        <QuestionsSlider
          questions={questions}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestion={setCurrentQuestion}
          answers={answers}
          updateAnswer={updateAnswer}
          loading={loading}
          handleSubmit={handleSubmit}
          reStart={reStart}
        />
      </div>
      <SaveValuesForm ref={submitForm} form={form} answers={answers} />
    </main>
  );
}
