import React from "react";
import cn from "classnames";

import QuestionOption from "./QuestionOption";

const QuestionOptions = ({ question, options, updateAnswer, answers }) => {
  const tooFewOptions = options.length <= 4;
  return (
    <div
      className={cn("flex gap-5 flex-wrap justify-center mt-8", {
        "text-center": !!tooFewOptions,
      })}
    >
      {options.map((o) => (
        <QuestionOption
          key={o.id}
          option={o}
          onClick={() => updateAnswer(question, o)}
          selected={answers.includes(o.id)}
          tooFewOptions={tooFewOptions}
        />
      ))}
    </div>
  );
};

const QuestionText = ({ question, updateAnswer, answers }) => (
  <div className="p-5 text-center">
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <textarea
          className="form-control my-2 bg-transparent text-white rounded-lg w-full focus-visible:ring-0 focus:ring-0 focus-visible:outline-none focus:outline-none"
          rows="4"
          value={answers}
          onChange={(e) => updateAnswer(question, e.target.value)}
        />
      </div>
    </div>
  </div>
);

const Kinds = {
  choose: QuestionOptions,
  text: QuestionText,
};

export default function QuestionCard({
  question,
  answers,
  updateAnswer,
  disabled,
}) {
  const Kind = Kinds[question.kind];

  return (
    <div
      className={cn("quote-form-question", {
        disabled: disabled,
      })}
    >
      <div className="shadow-sm">
        <div className="p-0 pt-5">
          <h1 className="text-2xl font-medium text-center my-2 mx-0 text-white md:mx-3 lg:mx-5">
            {question.title}
          </h1>
          <div className="text-center text-muted text-white">
            {question.description}&nbsp;
          </div>
          <div className="mt-2 px-3 lg:px-0">
            {Kind && (
              <Kind
                question={question}
                options={question.options}
                updateAnswer={updateAnswer}
                answers={answers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
