// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails"
);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
// import '../shared/stylesheets/tailwind.css';

import "@hotwired/turbo-rails";
import "../controllers";
import "@fortawesome/fontawesome-free/js/all.js";
import AOS from "aos";
// import '../shared/stylesheets/tailwind.scss';
import "../../assets/stylesheets/application.tailwind.css";
import "../src/mobile_menu.js";

document.addEventListener("turbo:render", function () {
  const isPreview = document.documentElement.hasAttribute(
    "data-turbolinks-preview"
  );
  if (!isPreview) AOS.init({ disableMutationObserver: true });
});

document.addEventListener("turbo:before-cache", function () {
  $(".aos-init,.aos-animate").removeClass(["aos-init", "aos-animate"]);
});

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({ disableMutationObserver: true });
});

import "trix";
import "@rails/actiontext";
