import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDom from "react-dom";

const componentRequireContext = import.meta.glob("../components/**/index.jsx",{ eager: true });
const ReactComponents = {};

Object.entries(componentRequireContext).forEach(([name, module]) => {
    const path = name.split("/");
    const componentName = path[path.length - 2];
    ReactComponents[componentName] = module.default;
});

export default class ReactComponentController extends Controller {
    static values = {
        name: String,
        props: Object,
    };

    connect() {
        const ReactComponent = ReactComponents[this.nameValue];
        if (ReactComponent) {
            ReactDom.render(<ReactComponent {...this.propsValue} />, this.element, () => {});
        } else {
            console.error("Could not find a ReactComponent with name: ", this.nameValue);
        }
    }
}