import {Controller} from "@hotwired/stimulus";
// import { enter, leave } from "el-transition";

export default class extends Controller {
    static values= {
        currentQuestion: Number,
        questions: Array,
    };
    connect() {
      console.log(this.currentQuestionValue);
    }

    next(event) {
        const oldQuestion = document.getElementById(`step-${this.currentQuestionValue}`)
        oldQuestion.classList.add("hidden")

        this.currentQuestionValue = this.currentQuestionValue + 1

        const newQuestion = document.getElementById(`step-${this.currentQuestionValue}`)
        newQuestion.classList.remove("hidden")
        // AOS.refresh();
        // console.log(AOS.refresh())
        enter(newQuestion)
        // newQuestion.setAttribute('data-aos', "flip-left");
        console.log("next")
    }
}