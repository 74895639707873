import React from "react";

import cn from "classnames";
import SVG from "react-inlinesvg";

import { Check } from "react-feather";

export default function QuestionOption({
  option,
  selected,
  onClick,
  tooFewOptions,
}) {
  return (
    <button
      type="button"
      className={cn(
        "quote-form-option w-full bg-dove-gray border rounded-lg shadow-lg px-2 py-3 block md:w-[calc(100%/2-2rem)] lg:w-[calc(100%/3-2rem)]",
        {
          "too-few-form-options": tooFewOptions,
          "transform transition-all border-primary text-white hover:bg-ternary":
            selected,
          "shadow-sm border-dove-gray-200": !selected,
        }
      )}
      onClick={onClick}
    >
      <div>
        <div>
          {!selected ? (
            <div className="w-5 h-5 border-dove-gray-200 border-2 rounded-md" />
          ) : (
            <div className="flex items-center justify-center w-5 h-5 border-primary bg-primary border-2 rounded-md">
              <Check size={20} className="text-dove-gray" />
            </div>
          )}
        </div>
        <div>
          <QuestionOptionIcon option={option} selected={selected} />
          <span
            className={cn(
              "block mt-2 order-2 text-base font-semibold break-words",
              {
                "text-primary": selected,
                "text-white": !selected,
              }
            )}
          >
            {option.label}
          </span>
        </div>
      </div>
    </button>
  );
}

const QuestionOptionIcon = ({ option, selected }) => {
  return (
    <div className="flex justify-center mt-2">
      <div className="w-12 h-12">
        <SVG
          src={option.image_path}
          className={cn("w-full h-full text-center", {
            "fill-primary": selected,
            "fill-white": !selected,
          })}
        />
      </div>
    </div>
  );
};
