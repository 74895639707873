import React from "react";

const GoBackButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className="transform transition-all text-custom-dark bg-white h-9 flex justify-center items-center px-9 text-xs rounded-2xl"
    onClick={onClick}
    disabled={disabled}
  >
    Voltar
  </button>
);

const NextButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className="transform transition-all text-custom-dark bg-primary h-9 flex justify-center items-center px-9 text-xs rounded-2xl"
    onClick={onClick}
    disabled={disabled}
  >
    Prosseguir
  </button>
);

const SubmitButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className="transform transition-all text-custom-dark bg-primary h-9 flex justify-center items-center px-9 text-xs rounded-2xl"
    onClick={onClick}
    disabled={disabled}
  >
    Enviar
  </button>
);

export default function QuoteFormButtons({
  goToPrevious,
  goToNext,
  canGoToNext,
  isFinished,
  onSubmit,
}) {
  return (
    <div className="mt-16 text-center flex items-center justify-between">
      <GoBackButton onClick={goToPrevious} />
      {isFinished ? (
        <SubmitButton onClick={onSubmit} disabled={!canGoToNext} />
      ) : (
        <NextButton onClick={goToNext} disabled={!canGoToNext} />
      )}
    </div>
  );
}
